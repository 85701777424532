import { css } from 'styled-components';

export const defaultRegular = css`
  font-size: 1.1rem;
  line-height: 15px;
  letter-spacing: 0;
  font-weight: 400;
`;

export const defaultMediumText = css`
  ${defaultRegular};
  font-weight: 500;
`;

export const defaultSemiBoldText = css`
  ${defaultRegular};
  font-weight: 600;
`;

export const defaultRegularBody = css`
  font-size: 1.2rem;
  line-height: 17px;
  letter-spacing: 0;
  font-weight: 400;
`;

export const defaultRegularOption = css`
  font-size: 1.4rem;
  line-height: 22px;
  letter-spacing: 0;
  font-weight: 400;
`;

export const defaultBoldButton = css`
  font-size: 1.3rem;
  line-height: 22px;
  letter-spacing: 0;
  font-weight: 700;
`;

export const defaultSemiBoldSmallTitle = css`
  font-size: 1.3rem;
  line-height: 22px;
  letter-spacing: -0.1px;
  font-weight: 600;
`;

export const defaultSemiBoldMediumTitle = css`
  font-size: 1.4rem;
  line-height: 17px;
  letter-spacing: -0.1px;
  font-weight: 600;
`;

export const defaultMediumMediumTitle = css`
  font-size: 1.4rem;
  line-height: 26px;
  letter-spacing: -0.1px;
  font-weight: 500;
`;

export const defaultSemiBoldTitle = css`
  font-size: 1.8rem;
  line-height: 20px;
  letter-spacing: -0.5px;
  font-weight: 600;
`;

export const defaultSemiBoldLargeTitle = css`
  font-size: 2.4rem;
  line-height: 28px;
  letter-spacing: -1px;
  font-weight: 600;
`;

export const defaultSemiBoldHeadline = css`
  font-size: 2.8rem;
  line-height: 34px;
  letter-spacing: -1px;
  font-weight: 600;
`;
