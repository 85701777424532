import styled, { css, keyframes } from 'styled-components';
import defaultTheme from '../../styles/themes/defaultTheme';

export const Container = styled.button<{ variant?: string }>`
  ${({ theme, variant }) => css`
    padding: 22px;
    width: 100%;
    /* max-width: 326px; */
    border-radius: 10px;
    max-height: 50px;
    ${theme.texts.defaultBoldButton};
    transition: ${theme.transitions.default};
    position: relative;

    ${variantStyles(variant)};

    display: flex;
    align-items: center;
  `};
`;

export const LoadingCircle = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: 16px;

    padding: 1.2px;
    border-radius: 50%;
    display: inline-block;
    background: conic-gradient(
      from 180deg at 50% 50%,
      #fff 0deg,
      rgba(255, 255, 255, 0) 360deg
    );

    transition: ${theme.transitions.default};
    animation: ${spinAnimation} 0.6s linear infinite;
  `}
`;

export const Circle = styled.div`
  ${({ theme }) => css`
    width: 12px;
    height: 12px;
    background-color: ${theme.colors.primary};
    border-radius: 50%;
    transition: ${theme.transitions.default};
  `}
`;

export const Icon = styled.div<{ position?: any }>`
  ${({ position }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;

    ${positionStyles(position)};

    > svg {
      width: 12px;
      height: 12px;
    }
  `};
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(-360deg); }
`;

const variantStyles = (variant = 'primary'): any => ({
  primary: css`
    color: ${defaultTheme.colors.neutralLight50};
    background-color: ${defaultTheme.colors.primary};

    :hover {
      background-color: ${defaultTheme.colors.primaryLight10};

      ${Circle} {
        background-color: ${defaultTheme.colors.primaryLight10};
      }
    }

    :active {
      background-color: ${defaultTheme.colors.primaryDark50};

      ${Circle} {
        background-color: ${defaultTheme.colors.primaryDark50};
      }
    }

    :disabled {
      background-color: ${defaultTheme.colors.neutralLight30};
      color: ${defaultTheme.colors.neutralLight50};
      cursor: not-allowed;

      ${Circle} {
        background-color: ${defaultTheme.colors.neutralLight30};
      }
    }
  `,
  secondary: css`
    color: ${defaultTheme.colors.primary};
    background-color: ${defaultTheme.colors.background};
    border: 1px solid ${defaultTheme.colors.primary};

    ${Circle} {
      background-color: ${defaultTheme.colors.background};
    }

    ${LoadingCircle} {
      background: conic-gradient(
        from 180deg at 50% 50%,
        ${defaultTheme.colors.primary} 0deg,
        rgba(255, 255, 255, 0) 360deg
      );
    }

    :hover {
      color: ${defaultTheme.colors.primaryLight10};
      border-color: ${defaultTheme.colors.primaryLight10};

      ${Circle} {
        background-color: ${defaultTheme.colors.background};
      }
    }

    :active {
      border-color: ${defaultTheme.colors.primaryDark50};
      color: ${defaultTheme.colors.primaryDark50};

      ${Circle} {
        background-color: ${defaultTheme.colors.background};
      }

      ${LoadingCircle} {
        background: conic-gradient(
          from 180deg at 50% 50%,
          ${defaultTheme.colors.primaryDark50} 0deg,
          rgba(255, 255, 255, 0) 360deg
        );
      }
    }

    :disabled {
      color: ${defaultTheme.colors.neutralLight20};
      border-color: ${defaultTheme.colors.neutralLight20};
      cursor: not-allowed;

      ${Circle} {
        background-color: ${defaultTheme.colors.background};
      }

      ${LoadingCircle} {
        background: conic-gradient(
          from 180deg at 50% 50%,
          ${defaultTheme.colors.neutralLight20} 0deg,
          rgba(255, 255, 255, 0) 360deg
        );
      }
    }
  `,
  tertiary: css`
    color: ${defaultTheme.colors.primaryDark50};
    background-color: ${defaultTheme.colors.background};

    ${Circle} {
      background-color: ${defaultTheme.colors.background};
    }

    ${LoadingCircle} {
      background: conic-gradient(
        from 180deg at 50% 50%,
        ${defaultTheme.colors.primaryDark50} 0deg,
        rgba(255, 255, 255, 0) 360deg
      );
    }

    :disabled {
      color: ${defaultTheme.colors.neutralLight20};
      cursor: not-allowed;

      ${Circle} {
        background-color: ${defaultTheme.colors.background};
      }

      ${LoadingCircle} {
        background: conic-gradient(
          from 180deg at 50% 50%,
          ${defaultTheme.colors.neutralLight20} 0deg,
          rgba(255, 255, 255, 0) 360deg
        );
      }
    }
  `,
}[variant]);

const positionStyles = (position: 'right'): any => ({
  right: css`
    right: 16px;
  `,
  left: css`
    left: 16px;
  `,
}[position]);
